<template>
  <b-card>
    <b-card-body>
      <h5 class="station-devices-heading">Station Devices</h5>

      <b-list-group class="station-devices-list-group">
        <b-list-group-item v-for="device of station.devices" :key="device.id">
          <b-row>
            <b-col class="station-devices-row">{{ device.name }} ({{ device.serialNumber }})</b-col>

            <b-col>
              <b-button @click="removeDevice(station, device)" size="sm" variant="outline-danger" class="float-right">
                Remove Device
              </b-button>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>

      <vue-bootstrap-typeahead
        ref="typeahead"
        class="station-devices-typeahead"
        v-model="typeaheadInput"
        :data="allDevices"
        :serializer="(s) => `${s.name} (${s.serialNumber})`"
        @hit="selectedDevice = $event"
        placeholder="Enter search"
      />

      <b-button @click="pushDevice(station)" class="add-station-button mr-2" size="sm" variant="outline-primary">
        Add Device
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import debounce from 'lodash.debounce';

import DeviceServiceV2 from '@/lib/device-service-v2';
import StationService from '@/lib/station-service';

export default {
  props: ['station'],
  components: {
    VueBootstrapTypeahead
  },
  watch: {
    typeaheadInput: debounce(function () {
      this.getAllDevices();
    }, 500)
  },
  data() {
    return {
      selectedDevice: null,
      typeaheadInput: '',
      allDevices: []
    };
  },
  methods: {
    async getAllDevices() {
      const organizationId = (await getAuth().sessionUser()).organizationUuid;
      const params = {
        search: this.typeaheadInput.length > 0 ? this.typeaheadInput : undefined,
        organizationId,
        active: this.active,
        pageSize: 10
      };

      try {
        const result = await DeviceServiceV2.listDevices(params);
        this.allDevices = result.data;
      } catch {
        this.$emit('error', 'Failed to load device list');
        this.allDevices = [];
      }
    },
    pushDevice(station) {
      if (this.selectedDevice) {
        station.devices.push(this.selectedDevice);
        station.deviceCount = station.devices.length;

        this.selectedDevice = null;

        this.$refs.typeahead.inputValue = '';

        StationService.updateStation(station.id, {
          deviceIds: station.devices.map((device) => device.id)
        }).catch(() => {
          this.$emit('error', 'Failed to update station devices');
          return [];
        });
      }
    },
    removeDevice(station, stationDevice) {
      station.devices = station.devices.filter((device) => device.id !== stationDevice.id);
      station.deviceCount = station.devices.length;

      StationService.updateStation(station.id, {
        deviceIds: station.devices.map((device) => device.id)
      }).catch(() => {
        this.$emit('error', 'Failed to update station devices');
        return [];
      });
    }
  }
};
</script>

<style scoped>
.station-devices-heading {
  margin-bottom: 1rem;
}
.station-devices-list-group {
  max-width: 60%;
}
.station-devices-row {
  align-self: center;
}
.station-devices-typeahead {
  margin-top: 1rem;
  max-width: 50%;
}
.add-station-button {
  margin-top: 1rem;
}
</style>
