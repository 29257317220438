<template>
  <div>
    <b-modal
      :id="modalId"
      v-model="modalShow"
      title="Edit Station"
      @show="resetForm"
      @hidden="resetForm"
      @ok="handleOk"
      no-close-on-backdrop
    >
      <b-form @submit.stop.prevent="onSubmit">
        <div v-if="editStationFailed" class="alert alert-danger">Failed to edit station: {{ errorMessage }}</div>

        <b-form-group label="Name" label-for="input-name" :invalid-feedback="veeErrors.first('input-name')">
          <b-form-input
            name="input-name"
            v-model="name"
            v-validate="{ required: true, min: 3 }"
            :state="validateState('input-name')"
            aria-describedby="input-name-feedback"
            data-vv-as="name"
            trim
          />
        </b-form-group>

        <b-form-group
          label="Description"
          label-for="input-description"
          :invalid-feedback="veeErrors.first('input-description')"
        >
          <b-form-input
            name="input-description"
            v-model="description"
            v-validate="{ required: false, min: 3 }"
            :state="validateState('input-description')"
            data-vv-as="description"
            trim
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import Axios from '@/axios';

export default {
  props: ['modalId', 'station'],
  data() {
    return {
      modalShow: false,
      editStationFailed: false,
      errorMessage: null,
      id: null,
      name: null,
      description: null
    };
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },

    resetForm() {
      this.editStationFailed = false;
      this.errorMessage = null;
      this.id = this.station.id;
      this.name = this.station.name;
      this.description = this.station.description;

      this.$nextTick(() => {
        this.$validator.reset();
      });
    },

    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    },

    async onSubmit() {
      const valid = await this.$validator.validateAll();
      if (!valid) {
        return;
      }

      const station = {
        name: this.name,
        description: this.description
      };

      try {
        await Axios.patch(`/goldrush-service/stations/${this.id}`, station);

        this.modalShow = false;
        this.$router.go();
      } catch (error) {
        this.editStationFailed = error;

        if (error.response) {
          this.errorMessage = error.response.data.errors[0].message;
        } else {
          this.errorMessage = 'An unexpected error occurred, please try again later.';
        }
      }
    }
  }
};
</script>

<style scoped></style>
